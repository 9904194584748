import { faker } from '@faker-js/faker'
import { Role } from '@heytutor/core'
import {
  Lesson as GQLesson,
  LessonItemContent,
  LessonItemContentType,
  LessonType,
  QuestionType,
  Session
} from 'graphql/generated'
import cache from 'js-cache'
import { get } from 'lodash'
import { nanoid } from 'nanoid'
import Router from 'next/router'
import { PORTAL_HOST } from 'utils/config'
import routes from 'utils/routes'
import Yup from 'utils/Yup'
import { SelectItem } from './ui'

export const lessonTypesNames = {
  [LessonType.Standard]: LessonType.Standard,
  [LessonType.Diagnostic1]: 'Diagnostic 1',
  [LessonType.Diagnostic2]: 'Diagnostic 2',
  [LessonType.Diagnostic3]: 'Diagnostic 3',
  [LessonType.Baseline]: LessonType.Baseline,
  [LessonType.Summative]: LessonType.Summative,
  [LessonType.Practice]: LessonType.Practice,
  [LessonType.Formative]: LessonType.Formative
}

export interface WithId {
  id?: string
}

export enum Level {
  Kindergarten = 'kindergarten',
  FirstGrade = 'FirstGrade'
}

export enum SlideType {
  GoogleSlide = 'google-slide',
  Editor = 'editor'
}

export interface GoogleSlideContent {
  title: string
  editLink: string
  publishLink: string
}

export interface Slide extends WithId {
  content: GoogleSlideContent //For Google Slide is the url, if is our custom editor either the content of the slide or an S3 url
  type: SlideType
  lessonId: string
}

export enum LessonItemType {
  Quiz = 'Quiz',
  Slide = 'Slice'
}

export interface LessonItem extends WithId {
  data: Quiz | Slide
  order: number
  type: LessonItemType
}

export function isQuiz(item: any): item is Quiz {
  return item
}

export function isSlide(item: any): item is Slide {
  return item
}

export function getLessonTypes(allTypes: boolean = false): SelectItem[] {
  return allTypes
    ? [
        {
          name: lessonTypesNames[LessonType.Standard],
          value: LessonType.Standard
        },
        {
          name: LessonType.Practice,
          value: LessonType.Practice
        },
        {
          name: LessonType.Baseline,
          value: LessonType.Baseline
        },
        {
          name: LessonType.Formative,
          value: LessonType.Formative
        },
        {
          name: LessonType.Summative,
          value: LessonType.Summative
        },
        {
          name: lessonTypesNames[LessonType.Diagnostic1],
          value: LessonType.Diagnostic1
        },
        {
          name: lessonTypesNames[LessonType.Diagnostic2],
          value: LessonType.Diagnostic2
        },
        {
          name: lessonTypesNames[LessonType.Diagnostic3],
          value: LessonType.Diagnostic3
        }
      ]
    : [
        {
          name: lessonTypesNames[LessonType.Standard],
          value: LessonType.Standard
        },
        {
          name: lessonTypesNames[LessonType.Diagnostic1],
          value: LessonType.Diagnostic1
        },
        {
          name: lessonTypesNames[LessonType.Diagnostic2],
          value: LessonType.Diagnostic2
        },
        {
          name: lessonTypesNames[LessonType.Diagnostic3],
          value: LessonType.Diagnostic3
        }
      ]
}

interface CurriculumCategorizedData extends WithId {
  title: string
  description?: string
  level: string
  subject: string
  metaTags: SelectItem[]
}

export interface Lesson extends CurriculumCategorizedData {
  internalTitle?: string
  items: LessonItem[]
  type: LessonType | ''
}

export interface LessonPath extends WithId {
  title: string
  description?: string
  level: string
  school: string
  semester: string
  subject?: string
  metaTags: SelectItem[]
}

export interface Quiz extends CurriculumCategorizedData {
  isGraded: boolean
  questions: Question[]
}

export interface Answer extends WithId {
  value: string
  isCorrect: boolean
  image?: string
}

export interface Question extends WithId {
  type?: any
  question: string
  explanation: string
  imageUrl?: string
  audioUrl?: string
  isGraded?: boolean
  forAssessment?: boolean
  feedback?: string
  answers: Answer[]
}

const baseSchema = {
  title: Yup.string().max(255, 'Must be at most 255 characters').required(),
  description: Yup.string().max(255, 'Must be at most 255 characters').nullable(),
  metaTags: Yup.array()
  //.min(2, 'You should add at least 2 tags.')
  //.required()
}

export const quizValidationSchema = Yup.mergeSchemas(baseSchema, {})

export const newLessonValidationSchema = Yup.mergeSchemas(baseSchema, {
  level: Yup.object({
    id: Yup.number().required()
  }),
  subject: Yup.object({
    id: Yup.number().required()
  }),
  items: Yup.array(),
  type: Yup.string().oneOf([
    LessonType.Standard,
    LessonType.Diagnostic1,
    LessonType.Diagnostic2,
    LessonType.Diagnostic3
  ])
})

export const lessonValidationSchema = Yup.mergeSchemas(baseSchema, {
  level: Yup.object({
    id: Yup.number().required()
  }),
  subject: Yup.object({
    id: Yup.number().required()
  }),
  items: Yup.array(),
  type: Yup.string()
    .oneOf([
      LessonType.Standard,
      LessonType.Practice,
      LessonType.Baseline,
      LessonType.Formative,
      LessonType.Summative,
      LessonType.Diagnostic1,
      LessonType.Diagnostic2,
      LessonType.Diagnostic3
    ])
    .required()
})

export const searchLessonValidationSchema = Yup.object({
  lesson: Yup.string().required(),
  subject: Yup.object({
    id: Yup.number()
  })
})

export const metatagValidationSchema = Yup.object({
  cc_standards: Yup.string().required().min(3, 'The CC Standards should contain at least 3 characters')
})

export const tutorNotesValidationSchema = Yup.object({
  name: Yup.string()
})

export const lessonPathValidationSchema = Yup.mergeSchemas(baseSchema, {
  level: Yup.object({
    id: Yup.number().required()
  }),
  school: Yup.object({
    id: Yup.number()
  }),
  subject: Yup.object({
    id: Yup.number()
  })
})

export const duplicateLessonPathValidationSchema = Yup.mergeSchemas(baseSchema, {
  school: Yup.object({
    id: Yup.number()
  })
})

export function createTags(): SelectItem[] {
  const items = ['Apple', 'Aperol', 'Maple', 'Start', 'Star Wars']

  return items.map((item) => createTag(item))
}

export function createTag(name: string): SelectItem {
  return {
    name,
    value: nanoid()
  }
}

export function getNewAnswer(value: string = '', isAnswer: boolean = false): Answer {
  return {
    value: value,
    isCorrect: isAnswer
  }
}

export function getQuestion(question: string = '', isAnswer: boolean = true): Question {
  return {
    question: question,
    explanation: '',
    isGraded: false,
    forAssessment: false,
    feedback: '',
    answers: [getNewAnswer('', isAnswer), getNewAnswer('', !isAnswer)]
  }
}

export function getQuiz(): Quiz {
  return {
    title: '',
    description: '',
    level: '',
    subject: '',
    isGraded: false,
    metaTags: [],
    questions: [getQuestion()]
  }
}

export function getLesson(): Lesson {
  return {
    title: '',
    internalTitle: '',
    description: '',
    level: '',
    subject: '',
    metaTags: [],
    items: [],
    type: ''
  }
}

export function getLessonPath(): LessonPath {
  return {
    title: '',
    description: '',
    level: '',
    school: '',
    subject: '',
    semester: '',
    metaTags: []
  }
}

class LocalStorageService<T extends WithId> {
  constructor(private readonly cacheKey: string) {
    cache.set(this.cacheKey, [])
  }

  private getAll(): T[] {
    return cache.get(this.cacheKey)
  }

  private set(items: T[]): void {
    cache.set(this.cacheKey, items)
  }

  create(item: T): T {
    const id = item.id || nanoid()
    const newItem = { ...item, id: id }
    const items = this.getAll()
    const newItems = [...items, newItem]

    this.set(newItems)

    return newItem
  }

  update(item: T): T[] {
    this.delete(item.id)
    this.create(item)

    return this.getAll()
  }

  delete(id: string): T[] {
    const items = this.getAll()
    const newItems = items.filter((x) => x?.id == id)

    this.set(newItems)

    return newItems
  }

  list(): T[] {
    const items = this.getAll()

    return items
  }

  getById(id: string): T | undefined {
    const items = this.getAll()

    return items.find((x) => x?.id == id)
  }
}

export class SessionCache {
  private getKey(id) {
    return `lesson:session:${id}`
  }

  getById(id: string): any | undefined {
    const result = window.localStorage.getItem(this.getKey(id))

    if (result && result != '') {
      return JSON.parse(result)
    }

    return undefined
  }

  delete(id: string) {
    window.localStorage.removeItem(this.getKey(id))
  }

  upsert(id: string, data: any, slide: number = 0) {
    const session = this.getById(id)
    const currentSlide = session && session.publicId == data.publicId ? session.currentSlide : slide

    window.localStorage.setItem(this.getKey(id), JSON.stringify({ ...data, currentSlide }))
  }

  updateCurrentSlide(id: string, slide: number) {
    const session = this.getById(id)

    if (session) {
      window.localStorage.setItem(this.getKey(id), JSON.stringify({ ...session, currentSlide: slide }))
    }
  }
}

class LessonService extends LocalStorageService<Lesson> {
  constructor() {
    super('lesson-storage')
  }
}

class QuizService extends LocalStorageService<Quiz> {
  constructor() {
    super('quiz-storage')

    const tags = createTags()
    const quiz1 = {
      title: faker.lorem.words(4),
      description: faker.lorem.words(7),
      level: '1',
      subject: '2',
      isGraded: true,
      metaTags: tags.slice(0, 2),
      questions: [getQuestion('Does the earth rotates?', true), getQuestion('Are humans amphibians?', false)]
    }

    this.create(quiz1)

    const quiz2 = {
      title: faker.lorem.words(4),
      description: faker.lorem.words(7),
      level: '1',
      subject: '2',
      isGraded: false,
      metaTags: tags.slice(1, 3),
      questions: [getQuestion('Is the earth sky green?', false), getQuestion('Does the earth translates?', true)]
    }

    this.create(quiz2)
  }
}

class SlideService extends LocalStorageService<Slide> {
  constructor() {
    super('slide-storage')
  }
}

export const quizService = new QuizService()

export const lessonService = new LessonService()

export const slideService = new SlideService()

export interface SlideShow {
  currentSlide: number
  items: SlideShowItem[]
  session?: any
}

export function isGradedActivity({ type }: { type: LessonType }, activity: LessonItemContent): boolean {
  switch (type) {
    case LessonType.Standard:
      return 'isGraded' in activity ? !!activity.isGraded : false
    case LessonType.Practice:
      return false
    default:
      return true
  }
}

export function toSlideShow(
  sessionId: string,
  lesson: GQLesson,
  preview = false,
  userRole: Role,
  isTutorLed: boolean,
  isOnline: boolean,
  allowControls: boolean,
  finishClass: () => Promise<void> = undefined,
  startNewLesson: () => Promise<void> = undefined
): SlideShow {
  const items = []

  const isLastItemAGradedActivity = () => {
    const lastItem = items[items.length - 1]

    return lastItem && lastItem.isGraded
  }

  if (!lesson.type) {
    return {
      currentSlide: 0,
      items
    }
  }

  const { isGraded } = lesson
  let questionCount = 0

  items.push({
    lessonId: lesson.id,
    title: lesson.title,
    description: lesson.description,
    canGoBack: false,
    canMoveForward: true,
    preview,
    type: SlideShowItemType.CoverSlide
  } as CoverSlide)

  let consecutiveQuestionsTotalIndex = 0
  const consecutiveQuestionsTotal = lesson.items.reduce(
    (previousValue, currentValue) => {
      if (currentValue.content.__typename == LessonItemContentType.Question) {
        previousValue[previousValue.length - 1] = previousValue[previousValue.length - 1] + 1

        return previousValue
      } else {
        if (previousValue[previousValue.length - 1] > 0) {
          return [...previousValue, 0]
        }

        return previousValue
      }
    },
    [0]
  )

  lesson.items.forEach((item) => {
    if (item.content.__typename == LessonItemContentType.Presentation) {
      questionCount = 0

      items.push({
        lessonId: lesson.id,
        lessonItemId: item.id,
        note: item.note,
        url: item.content.publishLink,
        audioUrl: item.content.audioUrl,
        canGoBack: preview || (!isGraded && !isLastItemAGradedActivity()),
        canMoveForward: true,
        preview,
        type: SlideShowItemType.GoogleSlide
      } as GoogleSlide)
    }

    if (item.content.__typename == LessonItemContentType.FillInGap) {
      const fillInGap = item.content

      questionCount++

      items.push({
        lessonId: lesson.id,
        lessonItemId: item.id,
        fillInGap: {
          id: fillInGap.id,
          text: fillInGap.text,
          words: fillInGap.words,
          instructions: fillInGap.instructions
        },
        isGraded: isGradedActivity(lesson, fillInGap),
        current: questionCount,
        total: consecutiveQuestionsTotal[consecutiveQuestionsTotalIndex],
        canGoBack: preview || (!isGraded && !fillInGap.isGraded && !isLastItemAGradedActivity()),
        note: item.note,
        canMoveForward: true,
        preview,
        type: SlideShowItemType.FillingBlanksSlide
      } as FillingBlanksSlide)

      if (consecutiveQuestionsTotal[consecutiveQuestionsTotalIndex] == questionCount) {
        consecutiveQuestionsTotalIndex++
      }
    }

    if (item.content.__typename == LessonItemContentType.Matching) {
      const matching = item.content

      questionCount++

      items.push({
        lessonId: lesson.id,
        lessonItemId: item.id,
        matching: {
          id: matching.id,
          matchingPairs: matching.matchingPairs,
          title: matching.title
        },
        isGraded: isGradedActivity(lesson, matching),
        current: questionCount,
        total: consecutiveQuestionsTotal[consecutiveQuestionsTotalIndex],
        canGoBack: preview || (!isGraded && !matching.isGraded && !isLastItemAGradedActivity()),
        note: item.note,
        canMoveForward: true,
        preview,
        type: SlideShowItemType.MatchingPairsSlide
      } as MatchingPairsSlide)

      if (consecutiveQuestionsTotal[consecutiveQuestionsTotalIndex] == questionCount) {
        consecutiveQuestionsTotalIndex++
      }
    }

    if (item.content.__typename == LessonItemContentType.Question) {
      const question = item.content

      questionCount++

      items.push({
        lessonId: lesson.id,
        lessonItemId: item.id,
        quizId: null,
        question: {
          id: question.id,
          type: question.type,
          explanation: question.explanation,
          question: question.question,
          feedback: question.feedback,
          imageUrl: question.imageUrl,
          audioUrl: question.audioUrl,
          answers: question.answers.map((x) => ({
            id: x.id,
            value: x.value,
            image: get(x, 'images[0].url'),
            isCorrect: x.isCorrect
          }))
        },
        isGraded: isGradedActivity(lesson, question),
        current: questionCount,
        total: consecutiveQuestionsTotal[consecutiveQuestionsTotalIndex],
        canGoBack: preview || (!isGraded && !question.isGraded && !isLastItemAGradedActivity()),
        note: question.type === QuestionType.MoodPoll ? question?.note : item.note,
        canMoveForward: true,
        preview,
        type: SlideShowItemType.QuestionSlide
      } as QuestionSlide)

      if (consecutiveQuestionsTotal[consecutiveQuestionsTotalIndex] == questionCount) {
        consecutiveQuestionsTotalIndex++
      }
    }

    if (item.content.__typename == LessonItemContentType.Quiz) {
      questionCount = 0

      const quiz = item.content
      const questions = item.content.questions

      items.push({
        lessonId: lesson.id,
        lessonItemId: item.id,
        quizId: quiz.id,
        title: `${quiz.title}${quiz.isGraded ? ' (Graded)' : ''}`,
        description: lesson.description,
        canGoBack: preview || (!quiz.isGraded && !isLastItemAGradedActivity()),
        note: item.note,
        canMoveForward: true,
        preview,
        type: SlideShowItemType.QuizSlide
      } as QuizSlide)

      questions.forEach((question, questionIndex) => {
        items.push({
          lessonId: lesson.id,
          lessonItemId: item.id,
          quizId: quiz.id,
          question: {
            id: question.id,
            explanation: question.explanation,
            question: question.question,
            feedback: question.feedback,
            answers: question.answers.map((x) => ({
              id: x.id,
              value: x.value,
              image: get(x, 'images[0].url'),
              isCorrect: x.isCorrect
            }))
          },
          isGraded: quiz.isGraded,
          current: questionIndex + 1,
          total: questions.length,
          canGoBack: preview || (!quiz.isGraded && !isLastItemAGradedActivity()),
          note: item.note,
          canMoveForward: true,
          preview,
          type: SlideShowItemType.QuestionSlide
        } as QuestionSlide)
      })
    }

    if (item.content.__typename == LessonItemContentType.FlashCard) {
      const { id, front, back, imageFrontUrl, imageBackUrl } = item.content

      questionCount++

      items.push({
        id,
        lessonId: lesson.id,
        lessonItemId: item.id,
        quizId: null,
        front,
        back,
        imageFrontUrl,
        imageBackUrl,
        current: questionCount,
        total: consecutiveQuestionsTotal[consecutiveQuestionsTotalIndex],
        canGoBack: preview || false,
        note:
          item.note ||
          'This flashcard activity reinforces key concepts and vocabulary. Engage students in active recall and discussion, and touch to flip the card when they are ready to see the answer',
        canMoveForward: true,
        preview,
        allowControls,
        type: SlideShowItemType.FlashCardSlide
      } as FlashCardSlide)

      if (consecutiveQuestionsTotal[consecutiveQuestionsTotalIndex] == questionCount) {
        consecutiveQuestionsTotalIndex++
      }
    }
  })

  const getRedirectBasedOnRole = (role: Role) => {
    switch (role) {
      case Role.TUTOR:
        return routes.schoolsFrontend.index()
      case Role.CONTENT_ADMIN:
        return `${PORTAL_HOST}${routes.editLesson(lesson.id)}`
      case Role.ADMIN:
        return `${PORTAL_HOST}${routes.editLesson(lesson.id)}`
      default:
        return routes.schoolsFrontend.index()
    }
  }

  const conditionalProperties = {
    ...(isTutorLed
      ? {
          description: 'Please hang tight! Your tutor will tell you what to do next.'
        }
      : {
          callToAction: () => Router.push(getRedirectBasedOnRole(userRole))
        }),
    ...(isTutorLed &&
      userRole === Role.TUTOR &&
      !isOnline && {
        description: 'How would you like to continue?',
        callToAction: finishClass,
        callToActionText: 'Finish class',
        secondaryCallToActionText: 'Start a new lesson',
        secondaryCallToAction: startNewLesson
      })
  }

  if (userRole == Role.HOME_STUDENT) {
    items.push({
      lessonId: lesson.id,
      canGoBack: preview || false,
      preview,
      canMoveForward: false,
      metadata: {
        endLesson: true,
        showScore: true
      },
      type: SlideShowItemType.ResultsSlide
    })
  } else {
    items.push({
      lessonId: lesson.id,
      title: "Congratulations! You've finished the lesson",
      description: 'Click finish button to return to your dashboard',
      canGoBack: preview || false,
      preview,
      canMoveForward: false,
      metadata: {
        endLesson: true,
        showScore: true
      },
      type: SlideShowItemType.CoverSlide,
      callToActionText: 'Finish',
      ...conditionalProperties
    } as CoverSlide)
  }

  return {
    currentSlide: 0,
    items
  }
}

export type SlideShowItem = CoverSlide &
  QuizSlide &
  QuestionSlide &
  GoogleSlide &
  FillingBlanksSlide &
  MatchingPairsSlide &
  FlashCardSlide

export enum SlideShowItemType {
  CoverSlide = 'CoverSlide',
  QuizSlide = 'QuizSlide',
  QuestionSlide = 'QuestionSlide',
  GoogleSlide = 'GoogleSlide',
  FillingBlanksSlide = 'FillingBlanksSlide',
  MatchingPairsSlide = 'MatchingPairsSlide',
  FlashCardSlide = 'FlashCardSlide',
  ResultsSlide = 'ResultsSlide'
}

export interface BaseSlideShowItem {
  lessonId: string
  lessonItemId?: string
  canGoBack: boolean
  canMoveForward: boolean
  preview: boolean
  type: SlideShowItemType
  metadata?: any
  session?: any
}

export interface CoverSlide extends BaseSlideShowItem {
  title: string
  description?: string
  callToAction?: () => Promise<void>
  callToActionText?: string
  secondaryCallToAction?: () => Promise<void>
  secondaryCallToActionText?: string
}

export interface QuizSlide extends BaseSlideShowItem {
  quizId: string
  title: string
  description?: string
}

export interface QuestionSlide extends BaseSlideShowItem {
  quizId: string
  question: Question
  isGraded: boolean
  current: number
  total: number
  session?: Partial<Session>
}

interface FillInGapSlideData {
  id: string
  text: string
  words: any[]
  instructions: String
}

interface MatchingPairsSlideData {
  id: string
  title: string
  matchingPairs: any[]
}

export interface FillingBlanksSlide extends BaseSlideShowItem {
  fillInGap: FillInGapSlideData
  isGraded: boolean
}

export interface MatchingPairsSlide extends BaseSlideShowItem {
  matching: MatchingPairsSlideData
  isGraded: boolean
}

export interface GoogleSlide extends BaseSlideShowItem {
  url: string
  audioUrl: string
}
export interface FlashCardSlide extends BaseSlideShowItem {
  id: string
  front: string
  back: string
  imageFrontUrl?: string
  imageBackUrl?: string
  publicId?: string
  allowControls: boolean
}

export interface ResultsSlide extends BaseSlideShowItem {}
