import router from 'next/router'
import { AUTH_ENDPOINT } from 'utils/config'
import routes from 'utils/routes'

type RouteParams = Record<string, string>

export function goTo(path: string, params: RouteParams = {}): Promise<boolean> {
  if (params) {
    const queryParams = Object.entries(params)
      .filter(([key]) => !path.includes(`{${key}}`))
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&')

    Object.entries(params).forEach(([key, value]) => {
      path = path.replace(`{${key}}`, encodeURIComponent(value))
    })

    if (queryParams) {
      path += `?${queryParams}`
    }
  }

  return router.push(path)
}

export function goToUrl(url: string, params: RouteParams = {}, target = '_self'): Promise<boolean> {
  if (params) {
    const queryParams = Object.entries(params)
      .filter(([key]) => !url.includes(`{${key}}`))
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&')

    Object.entries(params).forEach(([key, value]) => {
      url = url.replace(`{${key}}`, encodeURIComponent(value))
    })

    if (queryParams) {
      url += `?${queryParams}`
    }
  }

  return new Promise((resolve, reject) => {
    const win = window.open(url, target)

    if (win) {
      win.onload = () => resolve(true)
      win.onerror = () => reject()
    } else {
      reject()
    }
  })
}

export function goToLogin(): Promise<boolean> {
  return goToUrl(AUTH_ENDPOINT)
}

export function goToSchoolsPlatform(): Promise<boolean> {
  return goToUrl(routes.schoolsFrontend.index())
}
