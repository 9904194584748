import { authorizeUser, useAuthenticationStore } from '@heytutor/common'
import GlobalSpinner from 'components/global-spinner'
import { useEffect } from 'react'
import { PermissionProvider } from './PermissionProvider'

export function Guard({ children, guest = false }) {
  const user = useAuthenticationStore((state) => state.user)

  useEffect(() => {
    if (!guest && !user) {
      authorizeUser()
    }
  }, [guest, user])

  return !guest ? !user ? <GlobalSpinner /> : <PermissionProvider>{children}</PermissionProvider> : <>{children}</>
}
