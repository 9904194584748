import Pusher from 'pusher-js'
import { GRAPHQL_API_ENDPOINT } from 'utils/config'
import { Subscription } from 'zen-observable-ts'
import { Observable, Observer } from './Observable'

interface SubscriptionOperation {
  query: string
  variables?: Record<string, unknown>
  key: string
  context: any
}

interface ObserverLike<T> {
  next: (value: T) => void
  error: (err: any) => void
  complete: () => void
}

/** An abstract observable interface conforming to: https://github.com/tc39/proposal-observable */
interface ObservableLike<T> {
  subscribe(observer: ObserverLike<T>): {
    unsubscribe: () => void
  }
}

export default class PusherSubscription {
  private pusher: Pusher

  decompress: (result: string) => any

  constructor(options) {
    this.pusher = options.pusher

    if (options.decompress) {
      this.decompress = options.decompress
    } else {
      this.decompress = function (_result: string) {
        throw new Error(
          "Received compressed_result but PusherLink wasn't configured with `decompress: (result: string) => any`. Add this configuration."
        )
      }
    }
  }

  request(operation: SubscriptionOperation): ObservableLike<any> {
    const subscribeObservable = new Observable<any>((_observer: any) => {})
    // Capture the super method
    const prevSubscribe = subscribeObservable.subscribe.bind(subscribeObservable)

    // Override subscribe to return an `unsubscribe` object, see
    // https://github.com/apollographql/subscriptions-transport-ws/blob/master/src/client.ts#L182-L212
    subscribeObservable.subscribe = (
      observerOrNext: Observer<any> | ((value: any) => void),
      onError?: (error: any) => void,
      onComplete?: () => void
    ): Subscription => {
      // Call super
      if (typeof observerOrNext == 'function') {
        prevSubscribe(observerOrNext, onError, onComplete)
      } else {
        prevSubscribe(observerOrNext)
      }

      const observer = getObserver(observerOrNext, onError, onComplete)
      var subscriptionChannel: string
      const response = this.fetchOperation(operation)

      response
        .then((data) => {
          // If the operation has the subscription header, it's a subscription
          // const response = operation.getContext().response
          // Check to see if the response has the header
          subscriptionChannel = data?.extensions?.lighthouse_subscriptions?.channel

          if (subscriptionChannel) {
            // Set up the pusher subscription for updates from the server
            const pusherChannel = this.pusher.subscribe(subscriptionChannel)

            // Subscribe for more update
            pusherChannel.bind('lighthouse-subscription', (payload: any) => {
              this._onUpdate(subscriptionChannel, observer, payload)
            })
          } else {
            // This isn't a subscription,
            // So pass the data along and close the observer.
            observer.next(data)
            observer.complete()
          }
        })
        .catch((error) => {
          observer.error(error)
        })

      // Return an object that will unsubscribe _if_ the query was a subscription.
      return {
        closed: false,
        unsubscribe: () => {
          subscriptionChannel && this.pusher.unsubscribe(subscriptionChannel)
        }
      }
    }

    return subscribeObservable
  }

  _onUpdate(
    subscriptionChannel: string,
    observer: { next: Function; complete: Function },
    payload: { more: boolean; compressed_result?: string; result?: object }
  ): void {
    let result: any

    if (payload.compressed_result) {
      result = this.decompress(payload.compressed_result)
    } else {
      result = payload.result
    }

    if (result) {
      // Send the new response to listeners
      observer.next(result)
    }

    if (!payload.more) {
      // This is the end, the server says to unsubscribe
      this.pusher.unsubscribe(subscriptionChannel)
      observer.complete()
    }
  }

  async fetchOperation(operation) {
    const response = await fetch(GRAPHQL_API_ENDPOINT, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(operation)
    })
    const data = await response.json()

    return data
  }
}

// Turn `subscribe` arguments into an observer-like thing, see getObserver
// https://github.com/apollographql/subscriptions-transport-ws/blob/master/src/client.ts#L347-L361
function getObserver<T>(observerOrNext: Function | Observer<T>, onError?: (e: Error) => void, onComplete?: () => void) {
  if (typeof observerOrNext === 'function') {
    // Duck-type an observer
    return {
      next: (v: T) => observerOrNext(v),
      error: (e: Error) => onError && onError(e),
      complete: () => onComplete && onComplete()
    }
  } else {
    // Make an object that calls to the given object, with safety checks
    return {
      next: (v: T) => observerOrNext.next && observerOrNext.next(v),
      error: (e: Error) => observerOrNext.error && observerOrNext.error(e),
      complete: () => observerOrNext.complete && observerOrNext.complete()
    }
  }
}
