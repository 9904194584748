const INVALIDPUBLICID = 'INVALIDPUBLICID'
const errorsToGloballyIgnore = [INVALIDPUBLICID]

export const showToastOnErrorCode = (errorCode) => {
  return errorCode ? !errorsToGloballyIgnore.find(errorCode) : true
}

export const isInvalidPublicIdError = (errorCode) => {
  return errorCode ? errorCode === INVALIDPUBLICID : false
}
