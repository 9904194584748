import { logOut, useAuthenticationStore, User } from '@heytutor/common'
import { Role } from '@heytutor/core'
import { useRouter } from 'next/router'
import { ReactNode } from 'react'
import { allowedRoutesByRole } from 'utils/permission'

export const PermissionProvider = ({ children }: { children: ReactNode }) => {
  const user: User = useAuthenticationStore((state) => state.user)
  const router = useRouter()
  const actualPathname = router.pathname

  const validateForTutors = () => {
    return allowedRoutesByRole.tutor.includes(actualPathname)
  }

  const validateForStudents = () => {
    return allowedRoutesByRole.student.includes(actualPathname)
  }

  const validateForHomeStudents = () => {
    return allowedRoutesByRole.homeStudents.includes(actualPathname)
  }

  const validateForHomeGuardians = () => {
    return allowedRoutesByRole.homeGuardians.includes(actualPathname)
  }

  const validateForAdmins = () => {
    return allowedRoutesByRole.admin.includes(actualPathname)
  }

  const validationFunctionByRole = {
    [Role.TUTOR]: validateForTutors,
    [Role.SCHOOL_STUDENT]: validateForStudents,
    [Role.HOME_STUDENT]: validateForHomeStudents,
    [Role.HOME_GUARDIAN]: validateForHomeGuardians,
    [Role.SCHOOL_DISTRICT_ADMIN]: validateForTutors, // tutor access in order to preview the lesson
    [Role.SCHOOL_ADMIN]: validateForTutors, // tutor access in order to preview the lesson
    [Role.SCHOOL_TEACHER]: validateForTutors, // tutor access in order to preview the lesson
    [Role.HEYTUTOR_EMPLOYEE]: validateForTutors, // tutor access in order to preview the lesson
    [Role.ADMIN]: validateForAdmins,
    [Role.CONTENT_ADMIN]: validateForAdmins // for now ContentAdmin is same as Admin
  }

  const notAllowedRedirect = () => {
    switch (user.role) {
      case Role.TUTOR:
      case Role.SCHOOL_DISTRICT_ADMIN:
      case Role.SCHOOL_ADMIN:
      case Role.SCHOOL_TEACHER:
      case Role.HEYTUTOR_EMPLOYEE:
      case Role.SCHOOL_STUDENT:
      case Role.HOME_STUDENT:
      case Role.HOME_GUARDIAN:
        router.push(process.env.NEXT_PUBLIC_DISTRICT_SCHOOL_DASHBOARD_URL)
        break
      default:
        router.push('/404')
    }
  }

  const validateRole = () => {
    const isValidRole = validationFunctionByRole.hasOwnProperty(user.role)
    const isUserAllowed = isValidRole && validationFunctionByRole[user.role]()

    if (!isValidRole) {
      logOut()

      if (router.pathname !== '/404') {
        router.push('/404')

        return true
      }

      return false
    }

    if (!isUserAllowed) {
      notAllowedRedirect()
    }

    return isUserAllowed
  }

  return validateRole() ? <>{children}</> : <></>
}
