import { logOut, showErrorMessage } from '@heytutor/common'
import * as Sentry from '@sentry/nextjs'
import { multipartFetchExchange } from '@urql/exchange-multipart-fetch'
import { values } from 'lodash'
import Pusher from 'pusher-js'
import { createClient, dedupExchange, errorExchange, subscriptionExchange } from 'urql'
import { GRAPHQL_API_ENDPOINT, NEXT_PUBLIC_PUSHER_CLUSTER, PUSHER_APP_KEY } from 'utils/config'
import { showToastOnErrorCode } from 'utils/errorHandling'
import PusherSubscription from './pusher'

const pusherClient = new Pusher(PUSHER_APP_KEY, {
  cluster: NEXT_PUBLIC_PUSHER_CLUSTER,
  authEndpoint: `${GRAPHQL_API_ENDPOINT}/subscriptions/auth`
})
const pusherSubscription = new PusherSubscription({
  pusher: pusherClient
})
const client = createClient({
  url: GRAPHQL_API_ENDPOINT,
  requestPolicy: 'network-only',
  fetchOptions: {
    credentials: 'include'
  },
  exchanges: [
    dedupExchange,
    errorExchange({
      onError: async (error) => {
        try {
          Sentry.captureException(error)

          const authError = error.graphQLErrors.some(
            (e) =>
              e.extensions.category == 'authentication' ||
              e.extensions.category == 'authorization' ||
              e.message == 'Permission denied' ||
              e.message == 'Unauthenticated.'
          )

          if (authError) {
            logOut(false)
          } else {
            const validationErrors = error.graphQLErrors.filter((x) => x.extensions.category == 'validation')

            if (validationErrors.length > 0) {
              validationErrors.forEach((x) =>
                values(x.extensions.validation).forEach(
                  (validation) =>
                    //@ts-ignore
                    showToastOnErrorCode(x?.extensions?.validation?.code) && showErrorMessage(validation)
                )
              )
            } else {
              showErrorMessage('There was an error in our servers. Please contact our support team.')
            }
          }
        } catch (error) {}
      }
    }),
    multipartFetchExchange,
    subscriptionExchange({
      forwardSubscription: (operation) => ({
        subscribe: (sink) => {
          return pusherSubscription.request(operation).subscribe(sink)
        }
      })
    })
  ]
})

export default client
