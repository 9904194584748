import client from 'components/graphql/client'
import { DocumentNode } from 'graphql'

export enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE'
}

interface RequestOptions {
  baseUrl?: string
  method?: RequestMethod
  headers?: Record<string, string>
}

export async function executeRequest(
  endpoint: string,
  { baseUrl = window.location.origin, method = RequestMethod.GET, headers = {} }: RequestOptions = {}
) {
  let url = baseUrl.endsWith('/') ? baseUrl.substring(0, baseUrl.length - 1) : baseUrl

  url += '/'
  url += endpoint.startsWith('/') ? endpoint.substring(1) : endpoint

  const requestHeaders: Record<string, string> = {}

  requestHeaders['Accept'] = 'application/json'
  requestHeaders['Content-Type'] = 'application/json'

  for (const [key, value] of Object.entries(headers)) {
    requestHeaders[key] = value
  }

  try {
    const response = await fetch(url, {
      method,
      mode: 'cors',
      credentials: 'include',
      redirect: 'error',
      headers: requestHeaders
    })
    let responseData = null

    try {
      responseData = (await response.json()).data
    } catch (e) {}

    return responseData
  } catch (e) {
    throw new Error(`Request to "${url}" failed`, { cause: e as Error })
  }
}

export async function executeQuery(query: DocumentNode, variables = {}) {
  const { data, error } = await client.query<any>(query, variables).toPromise()

  if (error) {
    throw new Error(error.message)
  }

  return data
}

export async function executeMutation(mutation: DocumentNode, variables = {}) {
  const { data, error } = await client.mutation<any>(mutation, variables).toPromise()

  if (error) {
    throw new Error(error.message)
  }

  return data
}
