import '../styles/fonts.css'
import '../styles/global.css'
import './_app.scss'
import { Button, ChakraProvider, Text, VStack } from '@chakra-ui/react'
import * as Sentry from '@sentry/nextjs'
import { Guard } from 'components/auth'
import { LoadingContext, useLoadingContext } from 'components/layouts/LoadingContext'
import StatusErrorLayout from 'components/layouts/status-error-layout'
import { useDeployDetector } from 'hooks'
import { NextComponentType } from 'next'
import { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

const GraphQlProvider = dynamic(() => import('../components/graphql'), {
  ssr: false
})

type CustomAppProps = AppProps & {
  Component: NextComponentType & {
    guestPage?: boolean
  }
}

function App({ Component, pageProps }: CustomAppProps) {
  const loadingContext = useLoadingContext()
  const router = useRouter()
  const deployDetected = useDeployDetector()
  const guestPage = Component.guestPage ?? false

  useEffect(() => {
    if (deployDetected) {
      window.location.reload()
    }
  }, [deployDetected])

  const myErrorHandler = (error: Error) => {
    // Do something with the error
    // E.g. log to an error logging client here
    Sentry.captureException(error)
  }

  const ErrorFallback = ({ error, resetErrorBoundary }) => {
    return (
      <StatusErrorLayout>
        <VStack>
          <Text mb="8">Something went wrong:</Text>
          <pre>{error.message}</pre>
          <Button onClick={resetErrorBoundary}>Try again</Button>
        </VStack>
      </StatusErrorLayout>
    )
  }

  return (
    <ChakraProvider>
      <Head>
        <title>HeyTutor</title>
      </Head>
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
        <Guard guest={guestPage}>
          <GraphQlProvider>
            <LoadingContext.Provider value={loadingContext}>
              <Component {...pageProps} key={router.asPath} />
            </LoadingContext.Provider>
          </GraphQlProvider>
        </Guard>
      </ErrorBoundary>
    </ChakraProvider>
  )
}

/*
// Enable this if we know that our sentry account supports profiling
TODO: See this to improve logs: https://docs.sentry.io/platforms/javascript/guides/nextjs/performance/
export default dynamic(() => Promise.resolve(Sentry.withProfiler(App)), {
  ssr: false,
})
*/

export default dynamic(() => Promise.resolve(App), {
  ssr: false
})
